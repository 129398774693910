import React from 'react';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppAppBar from '../components/navbar/navbarvn';
import Banner from '../components/chung-chi-chat-luong/banner';
import Footer from '../components/homepagevn/Footer';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  grid2: {
    marginTop: theme.spacing(5),
  },
  grid21: {
    marginLeft: theme.spacing(15),
  },
  image: {
    margin: 'auto',
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
  },
  contentContainer: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
    },
  },
}));

const cards = [
  {
    url:
      'https://static.chinhdaisteel.com/quality_certification/chung-chi-1.jpg',
    title: 'VUSTA  JIS-G 3466',
    content: '',
  },
  {
    url:
      'https://static.chinhdaisteel.com/quality_certification/chung-chi-2.jpg',
    title: 'VUSTA JIS-G 3444',
    content: '',
  },
  {
    url:
      'https://static.chinhdaisteel.com/quality_certification/chung-chi-3.jpg',
    title: 'VUSTA JIS-G 3402',
    content: '',
  },
  {
    url:
      'https://static.chinhdaisteel.com/quality_certification/chung-chi-4.jpg',
    title: 'VUSTA AS/NZS 1163',
    content: '',
  },
  {
    url:
      'https://static.chinhdaisteel.com/quality_certification/chung-chi-5.jpg',
    title: 'VUSTA ASTM A500',
    content: '',
  },
  {
    url:
      'https://static.chinhdaisteel.com/quality_certification/chung-chi-6.jpg',
    title: 'ISO',
    content: '',
  },
];

export default function Intro5() {
  const classes = useStyles();

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title> CHỨNG CHỈ CHẤT LƯỢNG</title>
      </Helmet>
      <AppAppBar />
      <Helmet>
        <title> Chứng chỉ chất lượng - Thép Chính Đại</title>
      </Helmet>
      <Banner />
      <main>
        <Container className={classes.contentContainer}>
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <img src={card.url} alt="Chungchi1" className={classes.image} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      <br />
      <Footer />
    </>
  );
}
